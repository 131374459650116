import React from "react";
import logo from './assets/Kubeshark-logo.svg';
import './Header.sass';
import * as UI from "../UI"

import { NavDropdown } from "./NavDropdown/NavDropdown";
import { NavDivider } from "./NavDivider";
import { useRecoilValue } from "recoil";
import fullscreenViewAtom from "../../recoil/fullscreenView/atom";
import { appAuthEnabled } from "../../types/global";
import { AuthButton } from "../UI/AuthButton/AuthButton";

export const Header: React.FC = () => {
  const fullscreenView = useRecoilValue(fullscreenViewAtom)

  if (fullscreenView) {
    return <></>
  }

  return <div className="header">
    <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
      <img className="logo" src={logo} alt="logo" />
    </div>
    <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
      <UI.DocsLink />
      <NavDivider />
      {!appAuthEnabled() && <UI.ScriptingButton />}
      {appAuthEnabled() && <AuthButton size='small' />}
      <NavDivider />
      <NavDropdown />
    </div>
  </div>;
}
