import React from 'react'
import { Box, Typography } from '@mui/material'
import variables from '../../../variables.module.scss'
import { DisableTrafficCapturingButton } from '../DisableTrafficCapturingButton/DisableTrafficCapturingButton'
import { useRecoilValue } from 'recoil'
import packetCapturingStoppedAtom from '../../../recoil/packetCapturingStopped'
import { appStopTrafficCapturingDisabled } from '../../../types/global'
import { authorizeAction, AUTHZ_ACTIONS } from '../../UI/Auth/SamlAuth/Authorization'

export const DisableTrafficCapturingBanner = () => {
  if (appStopTrafficCapturingDisabled()) {
    return <></>
  }

  const capturingStopped = useRecoilValue(packetCapturingStoppedAtom)

  if (capturingStopped === null || !capturingStopped) {
    return <></>
  }

  return (
    <Box
      position='absolute'
      top={0}
      bottom={0}
      left={0}
      right={0}
      width='100%'
      height='100%'
      display='flex'
      alignItems='center'
      justifyContent='center'
      zIndex={2}
      sx={{
        backdropFilter: 'blur(10px)'
      }}
    >
      <Box
        padding='32px'
        display='flex'
        flexDirection='column'
        alignItems='center'
        gap='25px'
        justifyContent='center'
        borderRadius='6px'
        bgcolor='#ffffff'
        boxShadow={variables.lightSpreadShadow}
      >
        <Typography variant='h1' fontSize={24} fontWeight={600} color={variables.grayColor} fontFamily={'Roboto'}>
          Traffic Capture is Disabled!
        </Typography>
        <span style={{
          textAlign: 'center',
          lineHeight: '1.7em',
          color: variables.lightGrayColorAlt
        }}>
            Traffic capturing is disabled. Kubeshark is currently dormant.<br/>
            To start capturing and processing traffic, you need to enable traffic capturing.
        </span>
        <Box mt='15px'>
          <DisableTrafficCapturingButton
            authorized={authorizeAction(AUTHZ_ACTIONS.CAN_STOP_TRAFFIC_CAPTURING)}
            altView
          />
        </Box>
      </Box>
    </Box>
  )
}
