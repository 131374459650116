import React, { useRef } from 'react'
import { useDescope, useSession } from '@descope/react-sdk'
import { Dialog } from '@mui/material'
import { DescopeSignInForm } from './DescopeSignInForm'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import authSessionLogoutAtom from '../../../../recoil/authSessionLogout/atom'
import authSessionOkAtom from '../../../../recoil/authSessionOk/atom'
import { removeAuthSessionDetails } from '../AuthSessionDetails'
import showAuthDialogAtom from '../../../../recoil/showAuthDialog/atom'

import authSessionLogoutCompletedAtom from '../../../../recoil/authSessionLogoutCompleted/atom'
import DialogTransition from '../../DialogTransition/DialogTransition'
import { useAuth } from '../Auth'

export const OidcAuth: React.FC = () => {
  const { shouldAuthenticate } = useAuth()
  const { isAuthenticated, isSessionLoading } = useSession()

  const [showAuthDialog, setShowAuthDialog] = useRecoilState(showAuthDialogAtom)

  const [authSessionOk, setAuthSessionOk] = useRecoilState(authSessionOkAtom)
  const authSessionLogout = useRecoilValue(authSessionLogoutAtom)

  const descopeSdk = useDescope()

  React.useEffect(() => {
    if (isSessionLoading) {
      return
    }

    if (!isAuthenticated) {
      removeAuthSessionDetails()
    }

    setAuthSessionOk(isAuthenticated)
    setShowAuthDialog(shouldAuthenticate)
  }, [shouldAuthenticate, isAuthenticated, isSessionLoading])

  const renderComplete = useRef({ authSessionLogoutEffect: false})

  const setAuthSessionLogoutCompleted = useSetRecoilState(authSessionLogoutCompletedAtom)

  React.useEffect(() => {
    if (renderComplete.current['authSessionLogoutEffect']) {
      if (authSessionOk) {
        descopeSdk.logout().then(() => setAuthSessionLogoutCompleted(Date.now()))
      }
    }

    renderComplete.current['authSessionLogoutEffect'] = true
  }, [authSessionLogout])

  const handleAuthDialogClose = () => {
    const searchParams = new URLSearchParams(window.location.search)
    if (!searchParams.has('descope-login-flow')) {
      setShowAuthDialog(false)
    }
  }

  return (
    <Dialog
      open={showAuthDialog}
      TransitionComponent={DialogTransition}
      onClose={handleAuthDialogClose}
      aria-describedby="alert-dialog-slide-description"
      disableEscapeKeyDown
      sx={{ zIndex: 1400 }}
    >
      <DescopeSignInForm />
    </Dialog>
  )
}
