import React from "react";
import { TrafficViewer } from "../../TrafficViewer/TrafficViewer"
import "../../../index.sass"
import { TimezoneModal } from "../../modals/TimezoneModal/TimezoneModal";
import { Header } from '../../Header/Header'
import { ScriptingModal } from '../../modals/ScriptingModal/ScriptingModal'
import { JobsModal } from '../../modals/JobsModal/JobsModal'
import { useRecoilState } from 'recoil'
import scriptingModalOpenAtom from '../../../recoil/scriptingModalOpen'
import jobsModalOpenAtom from '../../../recoil/jobsModalOpen'
import { Box } from '@mui/material'

export const TrafficPage: React.FC = () => {
  const [scriptingModalOpen, setScriptingModalOpen] = useRecoilState(scriptingModalOpenAtom);
  const [jobsModalOpen, setJobsModalOpen] = useRecoilState(jobsModalOpenAtom);

  return (
    <Box height='100%' overflow='hidden'>
      <Header />
      <TrafficViewer />
      <TimezoneModal />
      {scriptingModalOpen && <ScriptingModal
        isOpen={scriptingModalOpen}
        onClose={() => setScriptingModalOpen(false)}
      />}
      {jobsModalOpen && <JobsModal
        isOpen={jobsModalOpen}
        onClose={() => setJobsModalOpen(false)}
      />}
    </Box>
  );
};
