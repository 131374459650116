import React, { useState } from 'react'
import { useRecoilState } from 'recoil'
import fullscreenViewAtom from '../../../recoil/fullscreenView/atom'
import { ButtonBase, Tooltip } from '@mui/material'
import { MinimizeIcon } from '../Icons/MinimizeIcon'
import { MaximizeIcon } from '../Icons/MaximizeIcon'
import variables from '../../../variables.module.scss'

export const FullscreenViewButton: React.FC = () => {
  const [showTooltip, setShowTooltip] = useState(false)
  const [fullscreenView, setFullscreenView] = useRecoilState(fullscreenViewAtom)

  return (
    <Tooltip
      open={showTooltip}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      title={fullscreenView ? 'Exit fullscreen view' : 'Enter fullscreen view'}
      placement='left'
      arrow
    >
      <ButtonBase
        onClick={() => {
          setFullscreenView(!fullscreenView)
          setShowTooltip(false)
        }}
        sx={{ borderRadius: '6px', backgroundColor: variables.headerBackgroundColor, padding: '2px' }}
      >
        {fullscreenView ? <MinimizeIcon stroke={variables.blueColor} /> : <MaximizeIcon stroke={variables.blueColor} /> }
      </ButtonBase>
    </Tooltip>
  )
}
