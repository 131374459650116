import React from "react";
import styles from './StatusCode.module.sass';
import Queryable from "../Queryable/Queryable";
import { Box } from "@mui/material";

export enum StatusCodeClassification {
  SUCCESS = "success",
  FAILURE = "failure",
  NEUTRAL = "neutral"
}

interface EntryProps {
  protocol: string
  statusCode: number,
  statusQuery: string
}

const StatusCode: React.FC<EntryProps> = ({ protocol, statusCode, statusQuery }) => {
  const isStatusCodeEnabled = (protocol === "http" || statusCode !== 0);

  if (!isStatusCodeEnabled) {
    return <></>
  }

  const classification = getClassification(statusCode)

  return <Box position='relative'>
    <Queryable
      query={statusQuery}
      displayIconOnMouseOver={true}
      flipped={true}
      iconStyle={{ position: 'absolute', top: "20px", left: '6px' }}
      tooltipStyle={{ position: 'absolute', top: '-10px', left: '8px' }}
    >
      <span
        title="Status Code"
        className={`${styles[classification]} ${styles.base}`}
      >
        {statusCode}
      </span>
    </Queryable>
  </Box>
};

export function getClassification(statusCode: number): string {
  let classification = StatusCodeClassification.NEUTRAL;

  // 1 - 16 HTTP/2 (gRPC) status codes
  // 2xx - 5xx HTTP/1.x status codes
  if (statusCode >= 200 && statusCode <= 399) {
    classification = StatusCodeClassification.SUCCESS;
  } else if (statusCode >= 400) {
    classification = StatusCodeClassification.FAILURE;
  }

  return classification
}

export default StatusCode;
