import { AUTHZ_ACTIONS, authorizeAction } from "../components/UI/Auth/SamlAuth/Authorization";
import { OidcAuthType, SamlAuthType } from "../consts";

declare global {
  interface Window {
    __RUNTIME_CONFIG__: {
      NODE_ENV: string;
      REACT_APP_DEFAULT_FILTER: string;
      REACT_APP_AUTH_ENABLED: string;
      REACT_APP_AUTH_TYPE: string;
      REACT_APP_AUTH_SAML_IDP_METADATA_URL: string;
      REACT_APP_TIMEZONE: string;
      REACT_APP_SCRIPTING_DISABLED: string;
      REACT_APP_TARGETED_PODS_UPDATE_DISABLED: string;
      REACT_APP_BPF_OVERRIDE_DISABLED: string;
      REACT_APP_RECORDING_DISABLED: string;
      REACT_APP_CLOUD_LICENSE_ENABLED: string;
      REACT_APP_STOP_TRAFFIC_CAPTURING_DISABLED: string;
      REACT_APP_SUPPORT_CHAT_ENABLED: string;
    };
  }
}

export const appAuthEnabled = (): boolean => {
  return window.__RUNTIME_CONFIG__.REACT_APP_AUTH_ENABLED === 'true'
}

export const appAuthType = (): string => {
  return window.__RUNTIME_CONFIG__.REACT_APP_AUTH_TYPE
}

export const appOidcAuthTypeEnabled = (): boolean => {
  return appAuthType() === OidcAuthType
}

export const appSamlAuthTypeEnabled = (): boolean => {
  return appAuthType() === SamlAuthType
}

export const appTimezone = (): string => window.__RUNTIME_CONFIG__.REACT_APP_TIMEZONE

export const appScriptingDisabled = (): boolean => {
  return (
    window.__RUNTIME_CONFIG__.REACT_APP_SCRIPTING_DISABLED === 'true'
  )
}

export const checkScriptingDisabled = () => {
  if (appScriptingDisabled()) {
    return true
  }

  if (!authorizeAction(AUTHZ_ACTIONS.CAN_USE_SCRIPTING)) {
    return true
  }

  return false
}

export const appTargetedPodsUpdateDisabled = (): boolean => {
  return (
    window.__RUNTIME_CONFIG__.REACT_APP_TARGETED_PODS_UPDATE_DISABLED === 'true'
  )
}

export const appBpfOverrideDisabled = (): boolean => {
  return (
    window.__RUNTIME_CONFIG__.REACT_APP_BPF_OVERRIDE_DISABLED === 'true'
  )
}

export const checkTargetedPodsUpdateDisabled = () => {
  if (appTargetedPodsUpdateDisabled()) {
    return true
  }

  if (!authorizeAction(AUTHZ_ACTIONS.CAN_UPDATE_TARGETED_PODS)) {
    return true
  }

  return false
}

export const appRecordingDisabled = (): boolean => {
  return (
    window.__RUNTIME_CONFIG__.REACT_APP_RECORDING_DISABLED === 'true'
  )
}

export const appCloudLicenseEnabled = (): boolean => {
  return (
    window.__RUNTIME_CONFIG__.REACT_APP_CLOUD_LICENSE_ENABLED === 'true'
  )
}

export const appStopTrafficCapturingDisabled = (): boolean => {
  return (
    window.__RUNTIME_CONFIG__.REACT_APP_STOP_TRAFFIC_CAPTURING_DISABLED === 'true'
  )
}

export const appSupportChatEnabled = (): boolean => {
  return (
    window.__RUNTIME_CONFIG__.REACT_APP_SUPPORT_CHAT_ENABLED === 'true'
  )
}
