import React from 'react'
import { Button, Tooltip, Typography } from '@mui/material'

import {
  LoginRounded as LoginRoundedIcon,
  LogoutRounded as LogoutRoundedIcon,
} from '@mui/icons-material'
import variables from '../../../variables.module.scss'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import showAuthDialogAtom from '../../../recoil/showAuthDialog/atom'
import authSessionLogoutAtom from '../../../recoil/authSessionLogout/atom'
import authSessionOkAtom from '../../../recoil/authSessionOk/atom'
import { getAuthSessionDetails } from '../Auth/AuthSessionDetails'

interface AuthButtonWideProps {
  size: 'small' | 'large' | 'medium'
  loginTooltip?: string
  logoutTooltip?: string
  disabled?: boolean
}

export const AuthButtonWide: React.FC<AuthButtonWideProps> = ({
  size,
  loginTooltip = 'You will be prompted to log in',
  logoutTooltip = `Logout from ${getAuthSessionDetails()?.email}`,
  disabled = false
}) => {
  const authSessionOk = useRecoilValue(authSessionOkAtom)
  const setAuthSessionLogout = useSetRecoilState(authSessionLogoutAtom)
  const setShowAuthDialog = useSetRecoilState(showAuthDialogAtom)

  const handleLogin = () => {
    setShowAuthDialog(true)
  }

  const handleLogout = () => {
    setAuthSessionLogout(Date.now())
  }

  return (
    <Tooltip arrow placement='top' title={authSessionOk ? logoutTooltip : loginTooltip}>
      <Button
        className={`themeButton ${authSessionOk ? 'secondary' : 'primary'}`}
        fullWidth
        variant={authSessionOk ? 'outlined' : 'contained'}
        size={size}
        startIcon={
          authSessionOk ?
            <LogoutRoundedIcon sx={{ marginRight: '5px' }} /> :
            <LoginRoundedIcon sx={{ marginRight: '5px' }} />
        }
        sx={{ backgroundColor: authSessionOk ? '' : variables.blueColor }}
        onClick={authSessionOk ? handleLogout : handleLogin}
        disabled={disabled}
      >
        <Typography
          variant='body1'
          fontSize={14}
          fontFamily={variables.textFontFamily}
          fontWeight={600}
          color={authSessionOk ? variables.blueColor : '#FFFFFF' }
        >
          {authSessionOk ? `Logout` : 'Login'}
        </Typography>
      </Button>
    </Tooltip>
  )
}
