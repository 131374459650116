import React from 'react';
import { Box } from '@mui/material'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import variables from '../../../../variables.module.scss'

interface TargetSettingDisabledCardProps {
  children?: React.ReactNode;
}

export const TargetSettingDisabledCard: React.FC<TargetSettingDisabledCardProps> = ({ children }) => {
  return (
    <Box
      width='100%'
      padding='8px'
      display='flex'
      alignItems='center'
      justifyContent='center'
      gap='9px'
      borderRadius='6px'
      border={`1px solid ${variables.lightestGrayColor}`}
      bgcolor='#ffffff'
      zIndex={2}
      boxSizing='border-box'
    >
      <SettingsOutlinedIcon
        htmlColor={variables.lightGrayColor}
      />
      {children}
    </Box>
  )
}
