import React from 'react'

import { getAuthSessionDetails } from '../Auth/AuthSessionDetails'

import authSessionOkAtom from '../../../recoil/authSessionOk/atom'
import { useRecoilValue } from 'recoil'

import variables from '../../../variables.module.scss'
import { Typography } from '@mui/material'

export const LoggedInAs: React.FC = () => {
  const authSessionOk = useRecoilValue(authSessionOkAtom)

  return (
    authSessionOk && (
      <Typography
        variant='body1'
        fontSize={14}
        fontFamily={variables.textFontFamily}
        fontWeight={600}
        color={variables.fontColor}
      >
        {getAuthSessionDetails()?.email && (
          <>
            <span>Logged in as</span>&nbsp;
            <span style={{ color: variables.blueColor }}>
              {getAuthSessionDetails().email}
            </span>
          </>
        )}
        {getAuthSessionDetails()?.email.length === 0 && (
          <span>You are logged in</span>
        )}
      </Typography>
    )
  )
}
