import React, { useCallback, useEffect } from 'react'
import variables from '../../../variables.module.scss'
import { Button, Tooltip, Typography } from '@mui/material'
import PowerSettingsNewRoundedIcon from '@mui/icons-material/PowerSettingsNewRounded'
import { useRecoilValue } from 'recoil'
import packetCapturingStoppedAtom from '../../../recoil/packetCapturingStopped'
import { appAuthEnabled, appSamlAuthTypeEnabled, appStopTrafficCapturingDisabled } from '../../../types/global'
import { useTrafficCapturingStatus } from '../../../hooks/useTrafficCapturingStatus'
import { checkSamlAuth } from '../../UI/Auth/SamlAuth/SamlAuth'

interface DisableTrafficCapturingButtonProps {
  authorized?: boolean
  altView?: boolean | string
}

export const DisableTrafficCapturingButton: React.FC<DisableTrafficCapturingButtonProps> = ({ authorized = true, altView = false }) => {
  if (appStopTrafficCapturingDisabled()) {
    return <></>
  }

  const { getCapturingStatus, postCapturingStoppedStatus } = useTrafficCapturingStatus()
  const capturingStopped = useRecoilValue(packetCapturingStoppedAtom)

  useEffect(() => {
    if (appAuthEnabled() && appSamlAuthTypeEnabled() && !checkSamlAuth()) {
      return
    }

    getCapturingStatus()
  }, [])

  const getTooltipTitle = useCallback(() => {
    if (authorized) {
      if (capturingStopped) {
        return 'Once ON, Kubeshark will start capture and process traffic'
      } else {
        return 'Once OFF, Kubeshark will stop processing traffic and become dormant'
      }
    } else {
      return 'You are not authorized to stop/resume traffic capture.'
    }
  }, [capturingStopped, authorized])

  if (capturingStopped === null) {
    return <></>
  }

  return (
    <Tooltip
      arrow
      placement={altView ? 'top' : 'right'}
      title={getTooltipTitle()}
    >
      <Button
        className={`themeButton ${altView ? capturingStopped ? 'success' : 'error' : capturingStopped ? 'error' : 'success outlined'}`}
        color={altView ? capturingStopped ? 'success' : 'error' : capturingStopped ? 'error' : 'success'}
        variant='outlined'
        size={altView ? 'medium' : 'small'}
        sx={{
          mt: '10px',
          opacity: authorized ? 1 : 0.5,
          cursor: authorized ? 'pointer' : 'not-allowed'
        }}
        startIcon={
          <PowerSettingsNewRoundedIcon
            htmlColor={altView ? '#ffffff' : capturingStopped ? '#ffffff' : variables.successColor}
            sx={{
              fontSize: altView ? 'unset' : '14px !important',
              marginRight: '5px'
            }}
          />
        }
        onClick={() => authorized ? postCapturingStoppedStatus(!capturingStopped) : false}
      >
        <Typography
          variant='body1'
          fontSize={altView ? 14 : 12}
          fontFamily={variables.textFontFamily}
          fontWeight={600}
        >
          {!altView && <span>Traffic Capture: {capturingStopped ? 'OFF' : 'ON'}</span>}
          {altView && <span>{capturingStopped ? 'Enable' : 'Disable'} Traffic Capture</span>}
        </Typography>
      </Button>
    </Tooltip>
  )
}
