import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import queryAtom from '../recoil/query/atom'
import queryBuildAtom from '../recoil/queryBuild/atom'
import queryBackgroundColorAtom from '../recoil/queryBackgroundColor/atom'
import { ColorYellow, LocalStorageKey } from '../consts'
import { useEffect } from 'react'
import { useAuth } from '../components/UI/Auth/Auth'

const DEFAULT_QUERY = window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_FILTER ? window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_FILTER.trim() : "" ;

const useQueryResolver = (): void => {
  const setQuery = useSetRecoilState(queryAtom)
  const setQueryBuild = useSetRecoilState(queryBuildAtom)
  const setQueryBackgroundColor = useSetRecoilState(queryBackgroundColorAtom)

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const { shouldAuthenticate } = useAuth()

  useEffect(() => {
    const querySearchParam = searchParams.get('q')
    if (querySearchParam) {
      setQueryBuild(querySearchParam)
      setQuery(querySearchParam)

      if (shouldAuthenticate) {
        localStorage.setItem(LocalStorageKey.ShouldRestoreQuery, querySearchParam)
      }
    } else {
      const shouldRestoreQuery = localStorage.getItem(
        LocalStorageKey.ShouldRestoreQuery
      )
      localStorage.removeItem(LocalStorageKey.ShouldRestoreQuery)

      const resolvedQuery = shouldRestoreQuery || DEFAULT_QUERY

      if (resolvedQuery.length > 0) {
        setQueryBuild(resolvedQuery)
        setQuery(resolvedQuery)
        setQueryBackgroundColor(ColorYellow)

        navigate({
          pathname: location.pathname,
          search: `q=${encodeURIComponent(resolvedQuery)}`
        })
      }
    }
  }, [])
}

export default useQueryResolver