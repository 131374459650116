import { atom } from "recoil";

export interface Node {
  name: string;
  ip: string;
}

const targetedNodesAtom = atom({
  key: "targetedNodesAtom",
  default: [] as Node[]
});

export default targetedNodesAtom;
