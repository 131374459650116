import { HealthState } from '../types/health'
import { useSetRecoilState } from 'recoil'
import streamingHealthAtom from '../recoil/streamingHealth'

export const useStreamingHealth = () => {
  const setStreamingHealth = useSetRecoilState(streamingHealthAtom)

  const reportStreamingHealth = (val: HealthState | null) => {
    setStreamingHealth((currentVal): HealthState => {
      if (val !== null) {
        val.count = (val.state === currentVal?.state) ? (currentVal?.count + 1) : 1;
      }

      return val
    })
  }

  return { reportStreamingHealth }
}
